import { Button, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ProteinViewer from "./ProteinViewer";

interface ExampleProps {
    name: string
    description?: JSX.Element | string
    json: string
    img: string
}

const Example = (props: ExampleProps) => {
    const { name, description, json, img } = props
    const navigate = useNavigate()

    const loadExample = async (fileName: string) => {
        fetch(`/examples/${fileName}`)
            .then(response => response.text())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(data)
                navigate("/editor", { state: { metadata: data, fileName: fileName } })
            })
    }

    return (
        <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <Stack spacing={2} justifyContent="center" alignItems="center" margin={2}>
                {/* <ProteinViewer pdb="1aki" /> */}
                <img src={"/examples/" + img} height={250} width={250} alt="Protein structure of {name}"/>
                <Typography variant="h3" fontWeight="bold">{name}</Typography>
                {description && <Typography>{description}</Typography>}
                <Button size="large" variant="contained" color="primary" onClick={() => loadExample(json)}>View metadata</Button>
            </Stack>
        </Paper>
    )
}

export default Example
