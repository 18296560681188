import { Stack, Grid, Typography } from "@mui/material"
import Dropzone from "../Components/Dropzone"
import Example from "../Components/Example"

const Frontpage = () => {

    return (
        <Stack direction="column" spacing={5}>
            <Typography variant="h1">About</Typography>
            <Typography>
                This tool is designed to help you analyze and edit the metadata of a GMX file.
                You can upload a TPR file to analyze its metadata and download the metadata in JSON or YAML format.
            </Typography>
            <Dropzone ></Dropzone>
            <Typography variant="h1" mb={5}>Examples</Typography>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Example name="Lysozyme in Water" description={<span>Showcase of metadata of simulation system with Lysozyme protein in water box with ions from a <a href="http://www.mdtutorials.com/gmx/lysozyme/">MDTutorials guide</a>.</span>} json="md_tutorial_lysozyme.json" img="1aki_assembly-1.jpeg" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Example name="Lysozyme in Water" description={<span>Showcase of metadata of simulation system with Lysozyme protein in water box with ions from a <a href="http://www.mdtutorials.com/gmx/lysozyme/">MDTutorials guide</a>.</span>} json="md_tutorial_lysozyme.json" img="1aki_assembly-1.jpeg" />
                </Grid>
                <Grid item xs={12} md={4}>                    
                    <Example name="Lysozyme in Water" description={<span>Showcase of metadata of simulation system with Lysozyme protein in water box with ions from a <a href="http://www.mdtutorials.com/gmx/lysozyme/">MDTutorials guide</a>.</span>} json="md_tutorial_lysozyme.json" img="1aki_assembly-1.jpeg" />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default Frontpage
